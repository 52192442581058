<template>
 <div class="s_user_cash_out_list_pop">
    <el-dialog :title="seeStatus == 1 ? '提现审核': '查看详情'" class="s-task-dialog" top="30px" width="1100px" :close-on-click-modal="false" :visible.sync="show">
                <el-form :style="formData.black_status == 0 ? {} : { 'backgroundColor': '#f5d8a1'}"  :model="formData" label-width="140px" size="mini">
                  <el-tabs v-model="activeName" type="card">
                    <!-- 提现信息 -->
                    <el-tab-pane label="提现信息" name="1">
                      <el-row>
                          <el-col :span="8">
                            <el-form-item label="ID:">
                              <span>{{ formData.id }}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-form-item label="用户手机号:">
                              <span>{{formData.mobile}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="8">
                            <el-form-item label="用户ID:">
                              <span>{{ formData.user_id }}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-form-item label="提现类型:">
                              <span>{{formData.title}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="8">
                            <el-form-item label="提现银行:">
                              <span>{{ formData.bank_name }}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="16">
                            <el-form-item label="提现卡号:">
                              <span>{{formData.card_number}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="8">
                            <el-form-item label="提现人姓名:">
                              <span>{{ formData.name }}</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-form-item label="提现申请时间:">
                              <span>{{formatDate(formData.create_time, 'yyyy-MM-dd hh:mm:ss')}}</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="8">
                            <el-form-item label="申请提现金额:">
                              <span>{{ formData.pay_money }}元</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-form-item label="手续费:">
                              <span>{{ formData.fee }}元</span>
                            </el-form-item>
                          </el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="8">
                            <el-form-item label="实际提现金额:">
                              <span>{{ formData.real_money }}元</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-form-item label="状态:">
                              <span v-if="formData.status==0" class="g-red">撤单</span>
                              <span v-else-if="formData.status==1" class="g-yellow">待审核</span>
                              <span v-else-if="formData.status==2" class="g-green">审核通过</span>
                              <span v-else-if="formData.status==3" class="g-blue">打款中</span>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8" v-show="formData.pay_img">
                            <el-form-item label="收款二维码:">
                              <el-image style="width: 120px; height: 120px" fit="cover" :src="formData.pay_img" :preview-src-list="[formData.pay_img]"></el-image>
                            </el-form-item>
                        </el-col>
                        </el-row>
                    </el-tab-pane>
                    <!-- 资金变动记录 -->
                    <el-tab-pane label="资金变动记录(近30天)" name="2" class="s-tabs-content-two">
                      <el-table v-loading="table.loading" class="g-self-scrollbar s-user-cash-ou-list-pop-table" ref="singleTable"  size="small" :data="table.rows" highlight-current-row  style="width: 100%">
                        <el-table-column prop="user_id" label="用户ID" width="120" :show-overflow-tooltip="true"/>
                        <el-table-column prop="key_id" label="记录编号" width="120" :show-overflow-tooltip="true"/>
                        <el-table-column label="资金类别" width="100" :show-overflow-tooltip="true">
                          <template  slot-scope="scope">
                            <span class="g-fsize14 g-red" v-show="scope.row.category === 1">本金结算</span>
                            <span class="g-fsize14 g-green" v-show="scope.row.category === 2">佣金结算</span>
                          </template>
                        </el-table-column>

                        <el-table-column label="类型" width="100" :show-overflow-tooltip="true">
                          <template  slot-scope="scope">
                            <span class="g-fsize14">{{scope.row.type | filtersType }}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="标题" :show-overflow-tooltip="true">
                          <template  slot-scope="scope">
                            <span class="g-fsize14">{{scope.row.title}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="变动金额" width="160" :show-overflow-tooltip="true">
                          <template  slot-scope="scope">
                            <span class="g-fsize14 g-yellow">{{scope.row.money}}</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="变更后余额" width="180" :show-overflow-tooltip="true">
                            <template slot-scope="scope">
                              <span class="g-fsize14 g-yellow">{{scope.row.balance}}元</span>
                            </template>
                        </el-table-column>
                      </el-table>

                      <el-pagination :page-sizes="[15, 30, 60, 100]" :page-size="query.limit" :total="table.total" :current-page.sync="query.page" @current-change="getList(false)" @size-change="sizeChange" small hide-on-single-page background layout="total, sizes, prev, pager, next, jumper"></el-pagination>
                    </el-tab-pane>
                  </el-tabs>

                  <el-row class="s-user-remark">
                    <el-col :span="18">
                      <el-form-item label="审核不通过理由:">
                         <el-input placeholder="请输入审核不通过理由" type="textarea" v-model="formData.remark" :autosize="{ minRows: 4, maxRows: 10 }" maxlength="100" show-word-limit></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>

        <div slot="footer" class="dialog-footer">
            <el-button @click="show = false" v-show="seeStatus === 2">取 消</el-button>
            <el-button type="primary" @click="show = false" v-show="seeStatus === 2">确定</el-button>
            <el-button type="danger" @click="apiCashFailHandel" v-show="seeStatus === 1">审核不通过</el-button>
            <el-button :type="formData.black_status == 0 ? 'success': 'danger'"  @click="apiCashApprovedHandel" v-show="seeStatus === 1">审核通过</el-button>
        </div>
    </el-dialog>

 </div>
</template>

<script>
import { formatDate } from '@/utils/index.js'
import { apiGetUserMoneyDay30, apiCashApproved, apiCashFail } from '@/utils/api.js'
export default {

  data() {
    return {
      seeStatus: 1, //1 审核 2查看
      query: {
        page:1, limit: 15, user_id: ''
      },

      table:{ loading:false, total:0, rows:[] },

      formData: {
        id: '',
        img: [],
        remark: '',
        userShop: { account : '' },
        platform: { title: '' },
        platformPlay: { title: '' },
        goods: [],
        task_type: { title: '' },
        search_keyword: [],
        entrance_pic: [],
        xdsj: { title: '' },
        huobi: { title: '' },
        liulan: { title: '' },
        main_liulan: [{ title: '' }],
        explain_img: [],
        xinyu: { title: '' },
        shop_limit: 0,
        account_limit: 0,
        close_time: 0,
        time_interval: 0,
        time_val: 0,
      },
      show: false,
      activeName: '1',
      // 任务回收时间
      taskTimeList: [
        { name: '2小时', value: 2 },
        { name: '4小时', value: 4 },
        { name: '8小时', value: 8 },
        { name: '12小时', value: 12 },
        { name: '24小时', value: 24 },
        { name: '48小时', value: 48 },
        { name: '当天24点', value: 0 },
      ],
    }
  },
  methods: {
    formatDate,
    // 审核不通过
    async apiCashFailHandel() {
      if(!this.formData.remark) return this.$message.error('请填写审核不通过理由')
      const loading = this.$loading()
      const { success, data } = await apiCashFail({ id: this.formData.id, remark: this.formData.remark }).catch(() => loading.close())
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      this.$emit('emitPass')
      this.onClose()
    },
    // 审核通过
    async apiCashApprovedHandel() {
      const loading = this.$loading()
      const { success, data } = await apiCashApproved({ id: this.formData.id }).catch(() => loading.close())
      loading.close()
      if(!success) return
      this.$message.success(data.msg)
      this.$emit('emitPass')
      this.onClose()
    },
    // 近30天资金记录
    async getList(pageInit = true) {
      if (pageInit) this.query.page = 1
      this.table.loading = true
      const { success, data } = await apiGetUserMoneyDay30(this.query)
      if(!success) return
      this.table.loading = false
      this.table.rows = data.rows
      this.table.total = data.total
      console.log(data)
    },
    //每页数量改变
    sizeChange(limit) {
      this.query.limit = limit
      this.getList()
    },
    // status //1 审核 2查看
    onShow(data, status) {
      console.log(status)
      this.seeStatus = status
      console.log(this.formData)
      this.formData = data
      this.query.user_id = data.user_id
      this.getList(data)
      this.show = true
    },
    onClose() {
      this.show = false
    }
  },
  filters: {
      filtersType(type) {
        if(type == 1) return '充值'
        if(type == 2) return '提现'
        if(type == 3) return '任务'
        if(type == 4) return '订单'
        if(type == 5) return '推广'
        return '其他'
      }
    },
}
</script>

<style lang='scss'>
.s_user_cash_out_list_pop {
  .el-dialog {
    max-height: 920px;
    margin: 0 auto;
  }
  .s-tabs-content-two {
    padding-bottom: 20px;
  }
  .s-user-cash-ou-list-pop-table {
    height: 500px;
    overflow: auto;
  }
  .s-user-remark {
    margin-top: 10px;
  }
}
</style>